import { Endpoint } from './../constants/endpoint';
import { Injectable, Injector } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { BaseModel } from '../models/base-model';
import { Result } from '../models/result-objects/result-model';
import { environment } from 'src/environments/environment';
import { map, catchError } from 'rxjs/operators';

export abstract class BaseService<T extends BaseModel> {


  protected http: HttpClient;
  constructor(
    protected apiPath: string,
    protected injector: Injector,
    protected jsonDataToResourceFn: (jsonData) => T
  ) {
    this.http = injector.get(HttpClient);
  }

  getAll(
    numeroPagina: number = 1,
    tamanhoPagina: number = 10,
  ): Observable<Result<T[]>> {
    const params = new HttpParams()
      .set('numeroPagina', numeroPagina.toString())
      .set('tamanhoPagina', tamanhoPagina.toString());

    return this.http
      .get<Result<T[]>>(
        `${Endpoint.Storage}/imagem`,
        {
          params: params,
          observe: 'response',
        }
      )
      .pipe(map(HttpResponseToResponsePagination));
  }

  get(id: any): Observable<T> {
    return this.http
      .get<T>(`${this.apiPath}/${id}`)
      .pipe(map((p) => p['resultado']));
  }

  getImage(): Observable<any>{
    return this.http.get("http://localhost:8402/imagens/26731001_885956351564977_6321120810980814854_n.jpg");
  }

  insert(entity: T) {
    return this.http.post(`${this.apiPath}`, entity);
  }

  update(id: string, entity: T) {
    return this.http.put(
      `${this.apiPath}/${id}`,
      entity
    );
  }

  updateList(entities: T[]) {
    return this.http.put(
      `${this.apiPath}`,
      entities
    );
  }

  saveChanges(entity: T) {
    Object.keys(entity).forEach((key) => (entity[key] == null || entity[key] === '') && delete entity[key]);
    if (entity.id == null) {
      return this.insert(entity);
    } else {
      return this.update(entity.id, entity);
    }
  }

  save(entity: T, id: any) {
    Object.keys(entity).forEach((key) => (entity[key] == null || entity[key] === '') && delete entity[key]);

    if (!id) {
      return this.insert(entity);
    } else {
      return this.update(id, entity);
    }
  }

  delete(id: string) {
    return this.http.delete(`${this.apiPath}/${id}`);
  }


  // PRIVATE METHODS
protected jsonDataToResources(jsonData: Result<T>): Result<T> {
    const resources: Result<T> = new Result<T>(
      jsonData.numeroPagina,
      jsonData.totalPaginas,
      jsonData.codigoRetorno,
      jsonData.mensagem,
      jsonData.dados,
      jsonData.totalItens,
      jsonData.erros
    );
    //console.log(jsonData);
    //jsonData.dados.forEach(element => resources.dados.push(this.jsonDataToResourceFn(element)));
    return resources;
}

protected jsonDataToResource(jsonData: Result<any>): T {
    return this.jsonDataToResourceFn(jsonData);
}
protected handleError(error: any): Observable<any> {
  console.log('Erro na requisição => ', error);
  return throwError(error);
}



}


export function HttpResponseToResponsePagination<T>(response: HttpResponse<Result<T>>): Result<any> {
	const pagination = JSON.parse(response.headers.get('x-pagination'));
	return new Result<T>(
    pagination.NumeroPagina,
		pagination.TotalPaginas,
    response.body['codigoRetorno'],
    response.body['mensagem'],
    response.body['dados'],
    pagination.Total,
		response.body['erros']
  );
}
