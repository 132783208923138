import { ImageCategory } from './image-category';
import { BaseModel } from "./base-model";

export class Image extends BaseModel{

  constructor(
    public id?: any,
    public fileName?: string,
    public urlLocal?: string,
    public urlCloud?: string,
    public urlThumbnail?: string,
    public idUsuario?: any,
    public createDate?: Date,
    public category?: ImageCategory,
    public tags?: string[]

  ) {
    super();
  }


static fromJson(jsonData: any): Image{
  return Object.assign(new Image(), jsonData);
}
}
