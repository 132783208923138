import { HomeComponent } from './home/home.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/auth.guard';

const routes: Routes = [
    {path: '', pathMatch: 'full', redirectTo: 'home'},
    {path: 'home', loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),  },
    {path: 'login', loadChildren: () => import('./auth/login/login.module').then((m) => m.LoginModule) },
    { path: 'register', loadChildren: () => import('./auth/register/register.module').then((m) => m.RegisterModule) },


];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
