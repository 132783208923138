import { Result } from './../models/result-objects/result-model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Endpoint } from './../constants/endpoint';
import { Image } from './../models/imagem-model';

import { Injectable,Injector } from '@angular/core';

import { BaseService, HttpResponseToResponsePagination } from './base-service';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Tag } from '../models/tag-model';
import { ImageDetail } from '../models/image-detail';

@Injectable({
  providedIn: 'root'
})
export class ImageService extends BaseService<Image> {

  constructor(protected injector: Injector,

  ) {
    super(`${Endpoint.Storage}/imagem`, injector, Image.fromJson)
  }

  getByTag(
    numeroPagina: number = 1,
    tamanhoPagina: number = 10,
    texto: string
  ): Observable<Result<Image[]>> {
    const params = new HttpParams()
      .set('numeroPagina', numeroPagina.toString())
      .set('tamanhoPagina', tamanhoPagina.toString())
      .set('txt', texto);

    return this.http
      .get<Result<Image[]>>(
        `${Endpoint.Storage}/Imagem/Tags`,
        {
          params: params,
          observe: 'response',
        }
      )
      .pipe(map(HttpResponseToResponsePagination));
  }

  obterTodasTags(
    ): Observable<Result<Tag[]>> {

        return this.http
            .get<Result<Tag[]>>(
                `${Endpoint.Storage}/imagem/tag`
            );
    }

    GetById(id: string ): Observable<Result<ImageDetail>> {  
          return this.http
              .get<Result<ImageDetail>>(
                  `${Endpoint.Storage}/imagem/Id?id=${id}`
              );
      }

      


}
