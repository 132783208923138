import { Injectable, NgModule } from '@angular/core';

import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { ServerErrorMessageService } from 'src/app/shared/components/server-error-messages/server-error-message.service';

@Injectable()

export class HttpsRequestInterceptor implements HttpInterceptor {

  constructor(private router: Router,
    private serverErrorMessage: ServerErrorMessageService
    ) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (sessionStorage.getItem('accessToken') != null) {
      const clonedReq = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + sessionStorage.getItem('accessToken'))
      });
      return next.handle(clonedReq).pipe(
        tap(
          succ => { },
          err => {
            if (err.status == 401) {
              sessionStorage.removeItem('accessToken');
              this.router.navigateByUrl('login');
            }

            if (err.status == 400) {
              const errorMessages: string[] = [];
              console.log(err);
              // if(err.error.length >= 1){
              //   err.error.forEach(element => {
              //     errorMessages.push(element.message);
              //   });

              //   this.serverErrorMessage.addMessageError(errorMessages);
            }

            else if (err.status == 403)
              this.router.navigateByUrl('login');
          }
        )
      )
    }
    else
      return next.handle(req.clone());
  }
}


@NgModule({
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpsRequestInterceptor,
      multi: true,
    },
  ],
})


export class Interceptor { }
