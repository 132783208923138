import { Observable, Observer, BehaviorSubject, Subject } from 'rxjs';

import { Injectable, OnInit } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ServerErrorMessageService{

  serverErrorMessage: string[] = [];
  observableMessages: BehaviorSubject<string[]>;;


  constructor() {
   this.serverErrorMessage = [];
   this.observableMessages = new BehaviorSubject<string[]>(this.serverErrorMessage);
  }





  eventChange() {
    this.observableMessages.next(this.serverErrorMessage);
  }

  addMessageError(msg: string[]){
    this.serverErrorMessage = [];
    this.serverErrorMessage = msg;
    this.eventChange();
  }
}
