import { HttpResponse } from '@angular/common/http';
import { Nofication } from "./notication.model";
import { Paginacao } from "./paginacao.modelt";

export class Result <T> implements Paginacao{
  numeroPagina: number;
	totalPaginas: number;
  codigoRetorno : number;
  mensagem: string;
  dados: T;
  totalItens : number;
  erros : Nofication[];

  /**
   *
   */
  constructor(
    numeroPagina: number,
    totalPaginas: number,
    codigoRetorno : number,
    mensagem: string,
    dados: any,
    totalItens : number,
    erros : Nofication[],
  ) {

    this.numeroPagina = numeroPagina;
    this.totalPaginas = totalPaginas;
    this.codigoRetorno = codigoRetorno;
    this.mensagem = mensagem;
    this.dados = dados;
    this.totalItens = totalItens;
    this.erros = erros;

  }

  /**
 * Faz o mapeamento do modelo de paginação
 * @param  {HttpResponse<Result<T>>} response
 * @returns Result
 */


}


export function HttpResponseToResponsePagination<T>(response: HttpResponse<Result<T>>): Result<any> {
	const pagination = JSON.parse(response.headers.get('x-pagination'));
  console.log(response);
	return new Result(
     pagination.numeroPagina,
		 pagination.tamanhoPagina,
     response.body['codigoRetorno'],
     response.body['mensagem'],
     response.body['dados'],
     pagination.totalItens,
     response.body['erros'],
	);


}

